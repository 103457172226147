<template>
  <div class='contact'>
    <h2 v-show="title == true">Contact Information</h2>
    <div>Phone: 614-445-6700</div>
    <div>Fax: 614-445-6203</div>
    <div>Email: <a href='mailto:info@robinstithlaw.com'>info@robinstithlaw.com</a></div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  props: {
    title: {
      type: Boolean,
      default: true
    }
  }
}

</script>

<style scoped lang='scss'>
  .contact {
    font-family: "Merriweather", serif;
    color: #7f7f7f;

    font-size: 0.8em;
    h2 {
      color: white;
      margin-top: 0px;
    }

    div {
      padding-bottom: 5px;
    }

    a {
      color: #7f7f7f;
      &:hover {
        color: #26bac5;
      }
    }
  }
</style>