import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '../views/welcome.vue'

import Service from '../views/service.vue'
import Blog from '../views/blog.vue'
import NotFound from '../views/not_found.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Welcome
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import(/* webpackChunkName: "services" */ '../views/services.vue')
  },
  {
    path: '/legal-advice',
    name: 'Legal Advice',
    component: () => import(/* webpackChunkName: "legal-advice" */ '../views/legal-advice.vue')
  },
  {
    path: '/blog',
    name: 'Blogs',
    component: () => import(/* webpackChunkName: "blogs" */ '../views/blogs.vue'),
  },
  {
    path: '/blog/pt-services/:id',
    name: 'Service',
    component: Service
  },
  {
    path: '/blog/2015/:id',
    name: 'Blog',
    component: Blog,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contact.vue'),
  },
  { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
