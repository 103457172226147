<template>
  <div id='header' class='container'>
    <div><img src='/img/header.png' /></div>
    <Contact :title='false' />
  </div>
</template>

<script>
import Contact from '@/components/contact.vue'
export default {
  name: 'Top',
  components: {
    Contact
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#header {
  background-color: #2F3439;
  height: 175px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  color: white;

  .contact {
    font-family: "Merriweather", serif;
    padding-top: 50px;
    color: #7f7f7f;

    font-size: 0.8em;

    div {
      padding-bottom: 5px;
    }

    a {
      color: #7f7f7f;
      &:hover {
        color: #26bac5;
      }
    }
  }
}
</style>
