<template>
  <div class="home">
    
    <h1 class='container'>"Justice delayed is justice denied." - William E. Gladstone</h1>
    <slider />

    <div id='tiles' class='container'>
      <div>
        <img src='/img/scale.png' />
        <h2>Bankruptcy</h2>
        <p> The BAPCPA Act of 2005 modified bankruptcy law throughout the country. Formally called the “Bankruptcy ... </p>
        <p> Read More </p>
      </div>
      <div>
        <img src='/img/scale.png' />
        <h2>Personal Injury</h2>
        <p> In 1969, when I was 13, I was involved in a serious auto accident. I was a backseat passenger in a ... </p>
        <p> Read More </p>
      </div>
      <div>
        <img src='/img/scale.png' />
        <h2>Family Law</h2>
        <p> Statistics show that 50% of all first marriages fail. 40% of those people remarry and then 60% of the ... </p>
        <p> Read More </p>
      </div>

    </div>

  </div>
</template>

<script>
import Slider from '@/components/slider.vue'

export default {
  name: 'Home',
  components: { 
    Slider
  }
}
</script>

<style scoped lang='scss'>
.home {
  padding-bottom: 50px;
  h1 {
    margin-top: 50px;
    font-family: "Merriweather", serif;
    font-weight: 300;
    padding-bottom: 15px;

  }

  #tiles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    div {
      margin: 10px;
      text-align: center;
      padding: 20px;
      padding-top: 30px;
      color: white;
      border-radius: 5px;

      &:nth-child(1) { background-color: #E34744; }
      &:nth-child(2) { background-color: #2F3439; }
      &:nth-child(3) { background-color: #26BAC5; }

      p {
        padding: 10px;
      }

      h2 {
        font-size: 2em;
        color: white;
        font-family: "Merriweather", serif;
      }
    }
  }
}

</style>
