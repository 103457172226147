<template>
  <div id='not-found'>
    <div class=' container'>
      <h1>The page you requested could not be found.</h1>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  data: function() {
    return { }
  },
  mounted() { 
  },
  methods: {

  },
  watch: {

  }
}

</script>

<style scoped lang='scss'>
  #not-found {
    padding-bottom: 30px;
    padding-top: 20px;
    h1 {
      font-family: "Merriweather", serif;
      margin-bottom: 10px;
    }

  }
</style>
