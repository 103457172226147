<template>
  <div class="service">
    <div class='container'>
      <div class='tile'  :class="service.color">
        <img src='/img/scale.png' />
        <h2>{{ service.title }}</h2>
      </div>
      <h5>{{ service.subtitle }}</h5>
    </div>
    <div class='content container'>
      <div v-html="service.content" />
      <div>
        <div>
          <Contact />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from '@/components/contact.vue'
export default {
  name: 'About',
  components: { 
    Contact
  },
  mounted() {
    this.updateTitle()
  },
  updated() {
    this.updateTitle()
  },
  methods: {
    updateTitle() {
      document.title = `${this.service.title || ''} | Services | The Law Offices of Robin S Stith – Robin Stith Law`
    }
  },
  computed: {
    service() {
      return this.$store.getters.services[this.$route.params.id]
    }
  }
}
</script>

<style scoped lang='scss'>
.service {
  .tile {
    margin-right: unset;
  }

  margin-top: 25px;
  padding-bottom: 50px;
  h1 {
    font-family: "Merriweather", serif;
    margin-bottom: 10px;
  }

  h5 {
    margin: 0px;
    padding-bottom: 10px;
    font-weight: 300;
    color: #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
  }

  img {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  .content {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;


    div:nth-child(1) {
      line-height: 1.5em;
      font-size: 15px;
      padding-right: 25px;
      text-align: justify;

      a:not(.btn) {
        font-weight: bold;
        color: #494949;
        text-decoration: none;

        &:hover {
          color: #26bac5;
        }
      }

      div.cta {
        margin-top: 25px;
      }
    }

    div:nth-child(2) {
      div {
        border-radius: 5px;
        background-color: #2F3439;
        padding: 15px;
      }
    }
  }
}

</style>
