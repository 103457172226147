<template>
  <ul id='nav' class='container'>
    <li><router-link to="/">Welcome</router-link></li>
    <li><router-link to="/about">About Our Offices</router-link></li>
    <li><router-link :class="servicesLinkClass" to="/services">Services We Provide</router-link></li>
    <li><router-link to="/legal-advice">Need Legal Help?</router-link></li>
    <li><router-link :class="blogLinkClass" to="/blog">Our Blog</router-link></li>
    <li><router-link to="/contact">Locate Us</router-link></li>
  </ul>
</template>

<script>
export default {
  name: 'Navigation',
  computed: {
    isServicePage() {
      return this.$route.name == 'Service'
    },
    isBlogPage() {
      console.log(this.$route.name)
      return this.$route.name == 'Blog'
    },
    servicesLinkClass() {
      return this.isServicePage ? 'router-link-exact-active router-link-active' : ''
    },
    blogLinkClass() {
      return this.isBlogPage ? 'router-link-exact-active router-link-active' : ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


ul#nav {
  
  width: auto;
  list-style: none;
  margin: 0;
  display: block;


  li {
    float: left;
    line-height: 77px;

    a {
      display: block;
      padding: 5px 22px 0px;
      font-family: "Merriweather", serif;
      font-size: 0.875em;
      text-decoration: none;

      color: #494949;

      &:hover {
        color: #26bac5;
        border-bottom: 0.438em solid #d5d5d5;
      }

      &.router-link-exact-active {
        color: #26bac5;
        border-bottom: 0.438em solid #d5d5d5;
      }
    }
  }
}


</style>
