<template>
  <div id='bottom' class='container'>
    <div>
      <img src='/img/footer.png' />
      <div>
        Robin S. Stith has served the needs of Columbus, Ohio for the past 35 years.
      </div>
      <div class='address'>
        <div>13 E Kossuth Street</div>
        <div>Columbus, OH 43206</div>
      </div>
    </div>
    <div />
    <Contact />
  </div>
</template>

<script>
import Contact from '@/components/contact.vue'
export default {
  name: 'Bottom',
  components: {
    Contact
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#bottom {
  clear: both;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #2F3439;
  // height: 150px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // color: #7f7f7f;

  img {
    width: 338px;
    height: 64px;
  }

  .address {
    padding-top: 20px;

  }
}
</style>
