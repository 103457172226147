<template>
  <div id="app">
    <Header />
    <Navigation />
  
    <div id='content' style='clear:both;'>
      <router-view/>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Navigation from '@/components/navigation.vue'
import Footer from '@/components/footer.vue'

export default {
  components: {
    Header,
    Navigation,
    Footer
  },
  data() {
    return {
      title: 'The Law Offices of Robin S Stith – Robin Stith Law'
    }
  },
  watch: {
    '$route'(to) {
      document.title = `${to.name || ''} | ${this.title}`
      window.scrollTo(0,0);
    } 
  }
}
</script>

<style lang="scss">
@import './app.scss';

div#content {
  border-top: 1px solid #d5d5d5;
}

</style>
