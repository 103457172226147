import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    services: {
      bankruptcy: { 
        title: 'Bankruptcy', 
        color: 'red', 
        subtitle: 'I have found that most people want to pay their bills but that sometimes life happens',
        content: `
          The BAPCPA Act of 2005 modified bankruptcy law throughout the country. Formally called the “Bankruptcy Abuse Prevention and Consumer Protection Act of 2005”, it was enacted by a naïve and misguided Congress who had been lead to believe that people who filed bankruptcy were doing so simply because they didn’t want to pay their bills. Nothing could have been or is further from the truth!
          <br /><br />
          In my 34+ years of bankruptcy practice, I have found that most people want to pay their bills but that sometimes life happens and the best of plans are messed up through no fault of our own. Job loss, layoff, death of spouse, illness, accident, disability, are all things that happen but that we did not plan to happen. Loss of income as a result of any of these things can be totally devastating.
          <br /><br />
          I am here to give my hands on loving care and legal expertise to you in the form of Chapter 7 or Chapter 13. I understand how you feel and I will not be the least bit judgmental of you or of your situation. I have had so many people tell me that they went to see an attorney who talked down to them and made them feel badly about their financial situation. That will not happen with me. I am here to fix your financial problems, if at all possible.
          <br /><br />
          I promise you that I will do everything in my power to help you. My legal team has been with me for over 15 years and they too are trained to help you. Whether through their experience, expertise or just with a much-needed prayer, they offer their assistance to you as well.
          <br /><br />
          Let me help you solve your financial problems TODAY!`
      },
      'family-law': { 
        title: 'Family Law', 
        color: 'blue', 
        subtitle: 'I believe that children should live in peace regardless of what the adults are going through.',
        content: `
          Statistics show that 50% of all first marriages fail. 40% of those people remarry and then 60% of the second marriages fail. Those are huge statistics that almost guarantee that in some way, you or a family member or a close friend will become involved in a proceeding at juvenile court or divorce court at some point in time.
          <br /><br />
          I personally know the havoc that termination of marriage can cause, whether it is by agreement (Dissolution) or by a court battle (Divorce). I know and understand the emotions. I know and understand what can happen to the children as they try to understand that mommy and daddy are not going to be together in the same house anymore. I can help in this most difficult time.
          <br /><br />
          I also know the current laws in place and I can assist you with matters of divorce, dissolution, custody, shared parenting, parenting time and child support. I am known for aggressively representing my clients, so that they are able to get all that they are entitled to by law and sometimes more, depending on the circumstances.
          <br /><br />
          I am not a mental health counselor, psychologist or psychiatrist but I am connected with them if you or your children need that kind of help during the family law process. I believe that children should live in peace regardless of what the adults are going through. As my mother says, “they did not ask to be here and it is not fair to catch them up in the middle of adult business”. I am here to help you and due to my many years of practice, I assure you that I know what I am doing.
          <br /><br />
          Family law services also include:
          <ul>
          <li>Custody</li>
          <li>Shared Parenting</li>
          <li>Visitation</li>
          <li>Child Support</li>
          </ul>

          Your family law case will be handled AGGRESSIVELY, effectively and with all the legal expertise that you need. Why go anywhere else?
          <br /><br />
          I am the attorney for you. Let me help your family today!` 
      },
      'personal-injury': {
        title: 'Personal Injury',
        color: 'black',
        subtitle: 'I know the tricks insurance companies play and most importantly, I know how to deal with them.',
        content: `
          In 1969, when I was 13, I was involved in a serious auto accident. I was a backseat passenger in a Volkswagen Beetle that got rear ended by a huge Buick Riviera in the midst of a bad rainstorm. The Beetle was totaled and the back, where I was sitting, was completely destroyed. We didn’t have seat belts in 1969 and cars were bigger and much heavier than they are today. Immediately, I heard something pop-snap and I became dizzy, nauseated and disoriented.
          <br /><br />
          By the time I got home, I couldn’t turn my head in any direction and I had a very bad headache. It took weeks for me to feel better. Since there wasn’t any visible injury, my parents didn’t see the need for me to go to the doctor or hospital.
          <br /><br />
          Today, over 45 years later, I still suffer from that injury. I have headaches, neck pain and muscle spasms that are all very painful. Today, my injury would be called “soft tissue” and would be laughed at, demeaned and trivialized by the insurance companies because those injuries don’t show on x-ray. Today, they say that “soft tissue” injuries mean nothing and the insurance company valuation of them is unfairly and extremely low. I know that those injuries do mean something and that they can last a lifetime.
          <br /><br />
          I will represent you zealously in your personal injury action against an insurance company. I know the tricks that they play and most importantly, I know how to deal with them. I promise you that I will aggressively pursue the insurance companies to get you compensated for your losses due to the accident. I can also assist you with other problems that sometimes arise out of accidents, like divorce and bankruptcy. You only need to go to one lawyer and that lawyer is me.
          <br /><br />
          We represent people involved in auto accidents, truck accidents, slip and fall accidents and medical malpractice. Please call us if you are involved in any kind of personal injury accident where you have suffered physical or emotional injury, lost wages and/or property damage, loss of consortium.
          <br /><br />
          Your injury case will be handled with the legal expertise of a seasoned attorney and with the care and concern of someone who still suffers pain caused by a negligent driver.
          <br /><br />
          Let me help you TODAY! All you have to do is get well. I will do the rest!`
      },
      'contempt': {
        title: 'Contempt',
        color: 'red',
        subtitle: null,
        content: `
          Is someone in your life violating a court order and they won’t stop?? You may have no choice but to file Contempt against them. You normally see this in family law situations when it comes to paying child support or visitation but there are other circumstances where such a motion is necessary to show someone that you mean business. A contempt finding can carry fines, costs, jail time and assessment of attorney fees among other things. You need a fighter, you need me.
          <br /><br />
          Let me help you resolve your contempt problems today!`
      },
      'estate-planning': {
        title: 'Estate Planning',
        color: 'black',
        subtitle: null,
        content: `
          No one wants to think about death or preparing for death but the truth of the matter is that we will all be there at one time or another. It is important to you and your family for you to be proactive. I can help answer your questions and prepare you on this part of life’s journey with a caring and knowledgeable legal hand.
          <ul>
          <li>Wills</li>
          <li>Trusts</li>
          <li>General POA</li>
          <li>Medical POA</li>
          </ul>
          Let me help you protect your estate today!`
      },
      'adoption': {
        title: 'Adoption',
        color: 'blue',
        subtitle: null,
        content: `
          Creating a new family through adoption is a loving gesture. Adoption procedure is complicated and has lots of paperwork that needs to be completed correctly. There are many rules that depend on the source of the adoption and the type of adoption. You can’t afford to be represented by someone who is not familiar with Probate Court. It is important to understand the process and have an attorney who can effectively guide you through that process.
          <br /><br />
          Let me help you start your family today!`
      },
      'expungement': {
        title: 'Expungement',
        color: 'red',
        subtitle: null,
        content: `
          Past criminal records can affect you, especially if it is your desire to work with children, senior citizens, the disabled or in the medical/nursing field. So many people misunderstand expungement. They don’t understand when they are entitled to expungement and when they aren’t.  Let me represent you in this tricky area of the law.
          <br /><br />
          Let me help you expunge your record today!`
      },
      'traffic-ovi': {
        title: 'Traffic & OVI',
        color: 'black',
        subtitle: null,
        content: `
          Traffic violations are nothing to laugh at. If serious enough, some traffic violations can cost you your driver’s license for years. Multiple OVI charges can lead to a felony with a lifetime suspension, jail time of up to 30 months, possible forfeiture of your vehicle and fines up to $10,500.00. The laws are steadily changing. You need to contact me immediately regardless of the violation/charge.
          <br /><br />
          Let me help you navigate your traffic & OVI violations today!`
      },
      'juvenile-criminal': {
        title: 'Juvenile Criminal',
        color: 'blue',
        subtitle: null,
        content: `
          Having a child in trouble with the law is scary and embarrassing. Juvenile criminal records can affect your child’s ability to obtain employment or continue on with his/her education. You need to contact me immediately regardless of the charge. It is important to understand juvenile court and to have an attorney who is familiar with the workings of juvenile law and juvenile court.
          <br /><br />
          Let me help you navigate your juvenile charges today!`
      },
      'adult-criminal': {
        title: 'Adult Criminal',
        color: 'red',
        subtitle: null,
        content: `
          Charged with a crime? That is a serious matter that requires the immediate attention of an experienced criminal attorney working on your behalf. Scared, overwhelmed, confused? It is important to understand your options and have an attorney who can effectively protect your rights and guide you through this legal process.
          <ul>
          <li>Adult Felony Criminal</li>
          <li>Adult Misdemeanor Criminal</li>
          </ul>
          Let me help you solve your criminal charges today!`
      }
    },
    blogs: {
      'personal-injury-law-columbus-oh': {
        title: 'Personal Injury Law in Columbus, OH',
        subtitle: 'Personal Injury Lawsuits',
        date: new Date('2015-08-15'),
        content: `
          I will represent you zealously in your personal injury action against an insurance company. 
          I know the tricks that they play and most importantly, I know how to deal with them. 
          I promise you that I will aggressively pursue the insurance companies to get you compensated 
          for your losses due to the accident. I can also assist you with other problems that sometimes 
          arise out of accidents, like divorce and bankruptcy. You only need to go to one lawyer and that lawyer is me.
          <br /><br />
          <a class='btn' href='/blog/pt-services/personal-injury'>Read more about how I can help you fight your personal injury case.</a>`
      },
      'family-law-columbus-oh': {
        title: 'Family Law in Columbus, OH',
        subtitle: 'Family Law, Dissolution & Divorce',
        date: new Date('2015-08-12'),
        content: `
        I personally know the havoc that termination of marriage can cause, whether it is by agreement (Dissolution) or by a court battle (Divorce). I know and understand the emotions. I know and understand what can happen to the children as they try to understand that mommy and daddy are not going to be together in the same house anymore. I can help in this most difficult time.
        <br /><br />
        <a class='btn' href='/blog/pt-services/family-law/'>Read more about how I can help you with family law in Columbus, OH.</a>`
      },
      'bankruptcy-columbus-oh': {
        title: 'Bankruptcy in Columbus, OH',
        subtitle: 'Bankruptcy, Chapter 7 & Chapter 13',
        date: new Date('2015-08-12'),
        content: `
        I understand how you feel and I will not be the least bit judgmental of you or of your situation. I have had so many people tell me that they went to see an attorney who talked down to them and made them feel badly about their financial situation. That will not happen with me. I am here to fix your financial problems, if at all possible.
        <br /><br />
        <a class='btn' href='/blog/pt-services/bankruptcy/'>Read more about how I can help you in filing for bankruptcy in Columbus, OH</a>
        `
      }
    }
  },
  getters: {
    services: state => state.services,
    blogs: state => state.blogs
  }
})

export default store