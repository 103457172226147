<template>
  <div id='slider'>
    <img v-show="index == 0" :src='images[0]' />
    <img v-show="index == 1" :src='images[1]' />
    <img v-show="index == 2" :src='images[2]' />
    <img v-show="index == 3" :src='images[3]' />
  </div>
</template>

<script>
export default {
  name: 'Slider',
  data() {
    return {
      images: [
        '/slides/slide1.png',
        '/slides/slide2.png',
        '/slides/slide3.png',
        '/slides/slide4.png'
      ],
      timer: null,
      index: 0
    }
  },
  mounted() {
    this.start()
  },
  methods: {
    start() {
      this.timer = setInterval(this.next, 10000)
    },
    next() {
      this.index++
      if (this.index == this.images.length)
        this.index = 0
    }
  },
  computed: {
    current() {
      return this.images[this.index]
    }
  }
}

</script>

<style scoped lang='scss'>
#slider {
  margin-top: 50px;
  margin-bottom: 20px;
  height: 543px;
}

img {
  width: 100%;
}

</style>